import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import LoginSection from './LoginSection.component';

const drawerWidth = 20;
const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: `${drawerWidth}rem`,
    backgroundColor: theme.palette.grayScale.g100,
    color: theme.palette.secondary.main,
    marginTop: '1.5rem',
  },
  mobileLoginSection: {
    background: theme.palette.grayScale.g0,
    color: theme.palette.secondary.main,
  },
  mobileButtonSection: {
    marginBottom: theme.spacing(2)
  },
  mobileLoginHeader: {
    paddingLeft: '1rem',
    '& .MuiButtonBase-root': {
      height: 'fit-content',
    }
  },
  mobileLoginContent: {
    position: 'relative',
  },
  notLoggedInMessage: {
    margin: `${theme.spacing(1)}px 0`,
  },
}));

const MobileDrawer = ({ open, anchorPosition, children, isLoggedIn = false, onCloseClick }) => {
  const classes = useStyles();
  return (
    <Drawer
      anchor={anchorPosition}
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={onCloseClick}
    >
      {children}
    </Drawer>
  );
};

MobileDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool.isRequired,
};

MobileDrawer.defaultProps = {
  open: false,
};

export default MobileDrawer;
